import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef, MatDialogModule, MatDialogContent, MatDialogTitle, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClaasIconElementsComponentModule } from '@claas/claas-icon-elements-component';
import { TranslateModule } from '@ngx-translate/core';
import { CdeFeConvertPipeModule } from 'cde-fe-convert-pipe';

export interface CancelLicenseSuccessModalInput {
  cancelDate: string;
}

@Component({
  selector: 'app-cancel-license-success-modal',
  templateUrl: './cancel-license-success-modal.component.html',
  styleUrl: './cancel-license-success-modal.component.scss',
  standalone: true,
  imports: [
    CdeFeConvertPipeModule,
    MatButtonModule,
    MatDialogModule,
    MatDialogContent,
    MatDialogTitle,
    TranslateModule,
    ClaasIconElementsComponentModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CancelLicenseSuccessModalComponent {
  public dialogRef = inject<MatDialogRef<CancelLicenseSuccessModalComponent>>(MatDialogRef<CancelLicenseSuccessModalComponent>);
  public modalInput = inject<CancelLicenseSuccessModalInput>(MAT_DIALOG_DATA);
}
