import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { AlarmSeverityCount, IAlarms, MachineListItem, MachineMarker, MachineOverview, MachinesImageUrl } from '@app/shared/models/machine-overview.model';
import { MachinesService } from '@app/shared/services/machines.service';
import * as RootState from '..';
import * as MachineOverviewActions from './machines.actions';
import { AlertService } from '@app/cde-toast/services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import {
  Category,
  IErrorResponse,
  IMachineArchiveDeleteRequest,
  IMachineOnboarding,
  IOnboardClaasMachineRequest,
  IOnboardClaasMachineResponse,
  IOtherMachineProduct,
  IPatchMachineHiddenRequest,
  IUpdateAllHiddenRequest,
} from '@app/shared/models/machines.model';
import { TOASTR_ALERT_OPTIONS } from '@app/cde-toast/utils/toastr-alert-options';
import { ProductService } from '@app/shared/services/product.service';
import { Router } from '@angular/router';
import { ToasterClass, ToasterPosition, ToasterType } from '@app/cde-toast/utils/toastr-options';
import { MachineImagesUrl } from './machines.actions';
import { MapMarker } from 'cde-fe-library-maps';
import { ServiceCallResponse } from '@app/shared/models/service-call.model';

@Injectable()
export class MachinesOverviewEffects {
  private store = inject<Store<RootState.IState>>(Store<RootState.IState>);
  private actions$ = inject(Actions);
  private machinesService = inject(MachinesService);
  private alertService = inject(AlertService);
  private translate = inject(TranslateService);
  private router = inject(Router);
  private productService = inject(ProductService);
  alertOptions = {
    ...TOASTR_ALERT_OPTIONS,
    timeOut: 3000,
  };

  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineOverviewActions.LIST),
      switchMap((data: { payload: string; type: string; machineId: string }) =>
        this.machinesService.listOverview().pipe(
          map((machines: MachineOverview[]) => new MachineOverviewActions.ListSuccess(machines, data.machineId)),
          catchError((error) => of(new MachineOverviewActions.ListFailure(error))),
          finalize(() => {
            this.store.dispatch(new MachineOverviewActions.ListComplete());
          })
        )
      )
    )
  );

  updateHidden$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MachineOverviewActions.UPDATE_HIDDEN),
        map((action: MachineOverviewActions.UpdateHidden) => action.payload),
        switchMap((payload: IPatchMachineHiddenRequest) =>
          this.machinesService.patchHiddenMachine(payload).pipe(
            take(1),
            finalize(() => {
              this.store.dispatch(new MachineOverviewActions.List(payload.timeZone));
            })
          )
        )
      ),
    { dispatch: false }
  );

  updateAllHidden$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MachineOverviewActions.UPDATE_ALL_HIDDEN),
        map((action: MachineOverviewActions.UpdateAllHidden) => action.payload),
        switchMap((payload: IUpdateAllHiddenRequest) =>
          this.machinesService.patchAllHiddenMachine(payload.data).pipe(
            take(1),
            finalize(() => {
              this.store.dispatch(new MachineOverviewActions.List(payload.timeZone));
            })
          )
        )
      ),
    { dispatch: false }
  );

  archiveMachine$ = createEffect(
    (): Observable<any> =>
      this.actions$.pipe(
        ofType(MachineOverviewActions.ARCHIVE_MACHINE),
        map((action: MachineOverviewActions.ArchiveMachine) => action.payload),
        switchMap((payload: IMachineArchiveDeleteRequest) =>
          this.machinesService.archiveMachineById(payload).pipe(
            take(1),
            map((response: IErrorResponse) => {
              if (payload.timeZone) {
                this.store.dispatch(new MachineOverviewActions.List(payload.timeZone));
              }
              this.alertService.callSnackbarSuccess(
                this.translate.instant('machine_overview.archive_machine_success'),
                '',
                ToasterType.snackbarSuccess, // custom attribute
                {}
              );
              return new MachineOverviewActions.ArchiveMachineSuccess({
                machineId: payload.machineId,
              });
            }),
            catchError((error) => {
              this.alertService.showError(
                this.translate.instant('machine_overview.archive_machine_failure'),
                this.translate.instant('alert_message.try_again'),
                'alert-error-icon',
                this.alertOptions
              );
              return of(new MachineOverviewActions.ArchiveMachineFailure(error));
            }),
            finalize(() => {
              this.store.dispatch(new MachineOverviewActions.ArchiveMachineComplete());
            })
          )
        )
      )
  );

  removeMachine$ = createEffect(
    (): Observable<any> =>
      this.actions$.pipe(
        ofType(MachineOverviewActions.REMOVE_MACHINE),
        map((action: MachineOverviewActions.RemoveMachine) => action.payload),
        switchMap((payload: IMachineArchiveDeleteRequest) =>
          this.machinesService.removeMachineById(payload).pipe(
            take(1),
            map((response: IErrorResponse) => {
              if (payload.timeZone) {
                this.store.dispatch(new MachineOverviewActions.List(payload.timeZone));
              }
              this.alertService.callSnackbarSuccess(
                this.translate.instant('machine_overview.delete_machine_success'),
                '',
                ToasterType.snackbarSuccess, // custom attribute
                {}
              );
              return new MachineOverviewActions.RemoveMachineSuccess({
                machineId: payload.machineId,
              });
            }),
            catchError((error) => {
              this.alertService.showError(
                this.translate.instant('machine_overview.delete_machine_failure'),
                this.translate.instant('alert_message.try_again'),
                'alert-error-icon',
                this.alertOptions
              );
              return of(new MachineOverviewActions.RemoveMachineFailure(error));
            }),
            finalize(() => {
              this.store.dispatch(new MachineOverviewActions.RemoveMachineComplete());
            })
          )
        )
      )
  );

  addOtherMachine$ = createEffect(
    (): Observable<any> =>
      this.actions$.pipe(
        ofType(MachineOverviewActions.ADD_OTHER_MACHINE),
        map((action: MachineOverviewActions.AddOtherMachine) => action.payload),
        switchMap((payload: { req: IOtherMachineProduct; timeZone: string }) => {
          const givenMachineType = payload.req.machineType ?? '';
          // remove machineType property from payload object
          const { machineType, ...rest } = payload.req;
          return this.productService.addOtherMachineOnboarding(rest, givenMachineType).pipe(
            map((response: IMachineOnboarding) => {
              this.alertService.callSnackbarSuccess(
                this.translate.instant('add_products.add_machine_success'),
                '',
                ToasterType.snackbarSuccess, // custom attribute
                {}
              );
              void this.router.navigate(['machines', 'details', response.id]);
              return new MachineOverviewActions.AddOtherMachineSuccess(response);
            }),
            catchError((error: ServiceCallResponse) => {
              if (error?.failCode === 409) {
                this.alertService.showError(
                  this.translate.instant('add_products.machine-extensions.errors.equipment-currently-assigned'),
                  this.translate.instant('alert_message.try_again'),
                  'alert-error-icon',
                  this.alertOptions
                );
              } else {
                this.alertService.showError(
                  this.translate.instant('add_products.add_machine_failure'),
                  this.translate.instant('alert_message.try_again'),
                  'alert-error-icon',
                  this.alertOptions
                );
              }
              return of(new MachineOverviewActions.AddOtherMachineFailure(error));
            }),
            finalize(() => {
              this.store.dispatch(new MachineOverviewActions.AddOtherMachineComplete());
              this.store.dispatch(new MachineImagesUrl());
            })
          );
        })
      )
  );

  machineMarkers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineOverviewActions.MACHINE_MARKERS),
      switchMap((data: { payload: string }) =>
        this.machinesService.getMachinesMarkers(data.payload).pipe(
          map((markers: Array<MapMarker>) => new MachineOverviewActions.MachineMarkersSuccess(markers)),
          catchError((error) => of(new MachineOverviewActions.MachineMarkersFailure(error))),
          finalize(() => {
            this.store.dispatch(new MachineOverviewActions.MachineMarkersComplete());
          })
        )
      )
    )
  );

  machineMarkerById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineOverviewActions.MACHINE_MARKER_BY_ID),
      switchMap((data: { uuid: string; timeZone: string; startDate?: string; endDate?: string; category?: Category }) => {
        const getMarkersCall =
          data.category === Category.IMPLEMENT
            ? this.machinesService.getImplementMarkerById(data.uuid, data.timeZone, data.startDate, data.endDate)
            : this.machinesService.getMachineMarkerById(data.uuid, data.timeZone, data.startDate, data.endDate);
        return getMarkersCall.pipe(
          map((machineMarker: MachineMarker) => {
            if (machineMarker.marker != null) {
              const mapMarker = {
                uuid: machineMarker.uuid,
                model: machineMarker.model,
                iconName: machineMarker.iconName,
                name: machineMarker.name,
                latitude: machineMarker.marker.latitude,
                longitude: machineMarker.marker.longitude,
                measuredAt: machineMarker.marker.measuredAt
              } as MapMarker;
              return new MachineOverviewActions.MachineMarkerByIDSuccess(mapMarker);
            } else {
              return new MachineOverviewActions.MachineMarkerByIDFailure('No position for marker available');
            }
          }),
          catchError((error) => {
            this.alertService.showError(
              this.translate.instant('alert_message.no_data_for_selected_period'),
              this.translate.instant('alert_message.try_again'),
              'alert-error-icon',
              this.alertOptions
            );
            return of(new MachineOverviewActions.MachineMarkerByIDFailure(error));
          }),
          finalize(() => {
            this.store.dispatch(new MachineOverviewActions.MachineMarkerByIDComplete());
          })
        );
      })
    )
  );

  machineMeasurements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineOverviewActions.MACHINE_MEASUREMENTS),
      switchMap((data: { payload: string }) =>
        this.machinesService.machineMeasurementsData(data.payload).pipe(
          map((machineMeasurements: MachineListItem[]) => new MachineOverviewActions.MachineMeasurementsSuccess(machineMeasurements)),
          catchError((error) => of(new MachineOverviewActions.MachineMeasurementsFailure(error))),
          finalize(() => {
            this.store.dispatch(new MachineOverviewActions.MachineMeasurementsComplete());
          })
        )
      )
    )
  );

  machineTopAlarms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineOverviewActions.MACHINE_TOP_ALARMS),
      switchMap((data: { uuid: string; timeZone: string; measuredAtLocalDate: string; language: string }) =>
        this.machinesService.getMachineTopAlarms(data.uuid, data.timeZone, data.measuredAtLocalDate, data.language).pipe(
          map((topAlarms: IAlarms) => new MachineOverviewActions.MachineTopAlarmsSuccess(topAlarms)),
          catchError((error) => of(new MachineOverviewActions.MachineTopAlarmsFailure(error))),
          finalize(() => {
            this.store.dispatch(new MachineOverviewActions.MachineTopAlarmsComplete());
          })
        )
      )
    )
  );

  machineImagesUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineOverviewActions.MACHINE_IMAGES_URL),
      switchMap(() =>
        this.machinesService.getMachineImagesUrl().pipe(
          map((machineImages: MachinesImageUrl) => new MachineOverviewActions.MachineImagesUrlSuccess(machineImages)),
          catchError((error) => of(new MachineOverviewActions.MachineImagesUrlFailure(error))),
          finalize(() => {
            this.store.dispatch(new MachineOverviewActions.MachineImagesUrlComplete());
          })
        )
      )
    )
  );

  machineSeverityCountsAlarms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineOverviewActions.MACHINE_SEVERITY_COUNTS_ALARMS),
      switchMap((data: { timeZone: string; measuredAtLocalDate: string }) =>
        this.machinesService.getMachineSeverityCountsAlarms(data.timeZone, data.measuredAtLocalDate).pipe(
          map(
            (machineAlarmSeverityCount: AlarmSeverityCount[]) =>
              new MachineOverviewActions.MachineSeverityCountsAlarmsSuccess(machineAlarmSeverityCount)
          ),
          catchError((error) => of(new MachineOverviewActions.MachineSeverityCountsAlarmsFailure(error))),
          finalize(() => this.store.dispatch(new MachineOverviewActions.MachineSeverityCountsAlarmsComplete()))
        )
      )
    )
  );

  onboardClaasMachine$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineOverviewActions.ONBOARD_CLAAS_MACHINE),
      map((action: MachineOverviewActions.OnboardClaasMachine) => action.request),
      mergeMap((request: IOnboardClaasMachineRequest) =>
        this.machinesService.onboardClaasMachine(request).pipe(
          map((onboardedMachine: IOnboardClaasMachineResponse) =>
            new MachineOverviewActions.OnboardClaasMachineSuccess(onboardedMachine)),
          catchError((error) => {
            this.alertService.showError(
              this.translate.instant('add_products.add_machine_failure'),
              this.translate.instant('alert_message.try_again'),
              'alert-error-icon',
              this.alertOptions
            );
            return of(new MachineOverviewActions.OnboardClaasMachineFailure(error))
          }),
          finalize(() => {
            this.store.dispatch(new MachineOverviewActions.OnboardClaasMachineComplete());
          })
        )
      )
    )
  );

  // navigate to details page after success full onboarding. That will trigger
  // requesting /master-data. Going to the machine overview will trigger a list
  // reload. Therefore all data neccessary is loaded after onboarding a new
  // machine
  onboardClaasMachineSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineOverviewActions.ONBOARD_CLAAS_MACHINE_SUCCESS),
      map((action: MachineOverviewActions.OnboardClaasMachineSuccess) => action.response),
      tap((response) => {
        this.alertService.showInfo(this.translate.instant('add_products.add_product_success'), '', ToasterType.snackbarSuccess, {
          // consistent with success effect when adding a foreign machine
          positionClass: ToasterPosition.bottomLeft,
          showToastAction: false,
          type: ToasterType.snackbarSuccess,
          toastClass: ToasterClass.snackbarSuccess,
          closeButton: false,
          disableTimeOut: false,
        });

        this.store.dispatch(new MachineImagesUrl());
        void this.router.navigate(['machines', 'details', response.id]);
      })
    ),
    { dispatch: false }
  );
}
