import { Injectable, inject } from '@angular/core';

import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, catchError, finalize, mergeMap, tap, withLatestFrom } from 'rxjs/operators';

import * as RootState from '@app/shared/state';
import * as MachineDigitalDeliveryAdviceActions from './machine.digital-delivery-advice.actions';

import { MachineDigitalDeliveryAdviceService } from '@app/shared/services/machine.digital-delivery-advice.service';
import { IDigitalDeliveryAdviceLegalTexts, IDigitalDeliveryAdviceRequest, IDigitalDeliveryAdviceSummary, IGetDigitalDeliveryAdviceResponse, IGetDigitalDeliveryAdvicesRequest, IUpdateDigitalDeliveryAdvice } from '@app/shared/models/machine.digital-delivery-advice.model';
import { ServiceCallResponse } from 'cde-fe-organization-registration-dialog/lib/models/service-call.model';
import { AlertService } from '@app/cde-toast/services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { TOASTR_ALERT_OPTIONS } from '@app/cde-toast/utils/toastr-alert-options';
import { IOnboardClaasMachineError } from '@app/shared/models/machines.model';
import { Paginated } from '@app/shared/models/page.model';
import { Features } from '@app/shared/models/feature-toggle.model';

@Injectable()
export class MachineDigitalDeliveryAdviceEffects {
  private store = inject<Store<RootState.IState>>(Store<RootState.IState>);
  private actions$ = inject(Actions);
  private digitalDeliveryAdviceService = inject(MachineDigitalDeliveryAdviceService);
  private alertService = inject(AlertService);
  private translateService = inject(TranslateService);

  /* eslint-disable @typescript-eslint/member-ordering */
  getDigitalDeliveryAdvice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineDigitalDeliveryAdviceActions.GET_DIGITAL_DELIVERY_ADVICE),
      withLatestFrom(this.store),
      map(([action, state]: [MachineDigitalDeliveryAdviceActions.GetDigitalDeliveryAdvice, RootState.IState]) => {
        const hasMultiOrgMemberRecipientFeature =
          state.featureToggle.featureToggles.some(f => f.id === Features.DIGITAL_DELIVERY_MULTIPLE_ORG_MEMBER_RECIPIENTS);
        const result = [action.request, hasMultiOrgMemberRecipientFeature] as [IDigitalDeliveryAdviceRequest, boolean];
        return result;
      }),
      mergeMap(([request, hasMultiOrgMemberRecipientFeature]: [IDigitalDeliveryAdviceRequest, boolean]) =>{
        let requestObservable: Observable<IGetDigitalDeliveryAdviceResponse>;
        if (hasMultiOrgMemberRecipientFeature) {
          requestObservable = this.digitalDeliveryAdviceService.getDigitalDeliveryAdvice(request);
        } else {
          requestObservable = this.digitalDeliveryAdviceService.getDigitalDeliveryAdviceV3(request);
        }
        return requestObservable.pipe(
          map((digitalDeliveryAdviceResponse: IGetDigitalDeliveryAdviceResponse) =>
            new MachineDigitalDeliveryAdviceActions.GetDigitalDeliveryAdviceSuccess(request, digitalDeliveryAdviceResponse)),
          catchError((error) => of(new MachineDigitalDeliveryAdviceActions.GetDigitalDeliveryAdviceFailure(error))),
          finalize(() => {
            this.store.dispatch(new MachineDigitalDeliveryAdviceActions.GetDigitalDeliveryAdviceComplete());
          })
        );
      })
    )
  );

  updateDigitalDeliveryAdvice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineDigitalDeliveryAdviceActions.UPDATE_DIGITAL_DELIVERY_ADVICE),
      mergeMap((updateDigitalDeliveryAdviceAction: MachineDigitalDeliveryAdviceActions.UpdateDigitalDeliveryAdvice) =>
        this.digitalDeliveryAdviceService
          .updateDigitalDeliveryAdviceAuthorizedPerson(
            updateDigitalDeliveryAdviceAction.request,
            updateDigitalDeliveryAdviceAction.updateDigitalDeliveryAdvice
          )
          .pipe(
            map(
              () =>
                new MachineDigitalDeliveryAdviceActions.UpdateDigitalDeliveryAdviceSuccess(
                  updateDigitalDeliveryAdviceAction.request,
                  updateDigitalDeliveryAdviceAction.updateDigitalDeliveryAdvice
                )
            ),
            catchError((error) => of(new MachineDigitalDeliveryAdviceActions.UpdateDigitalDeliveryAdviceFailure(error))),
            finalize(() => {
              this.store.dispatch(new MachineDigitalDeliveryAdviceActions.UpdateDigitalDeliveryAdviceComplete());
            })
          )
      )
    )
  );

  updateDigitalDeliveryAdviceOrgMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineDigitalDeliveryAdviceActions.UPDATE_DIGITAL_DELIVERY_ADVICE_ORG_MEMBERS),
      mergeMap((updateDigitalDeliveryAdviceAction: MachineDigitalDeliveryAdviceActions.UpdateDigitalDeliveryAdviceOrgMembers) =>
        this.digitalDeliveryAdviceService
          .updateDigitalDeliveryAdviceOrgMembers(
            updateDigitalDeliveryAdviceAction.request,
            updateDigitalDeliveryAdviceAction.updateDigitalDeliveryAdvice
          )
          .pipe(
            map(
              () =>
                new MachineDigitalDeliveryAdviceActions.UpdateDigitalDeliveryAdviceOrgMembersSuccess(
                  updateDigitalDeliveryAdviceAction.request,
                  updateDigitalDeliveryAdviceAction.updateDigitalDeliveryAdvice
                )
            ),
            catchError((error) => of(new MachineDigitalDeliveryAdviceActions.UpdateDigitalDeliveryAdviceOrgMembersFailure(error))),
            finalize(() => {
              this.store.dispatch(new MachineDigitalDeliveryAdviceActions.UpdateDigitalDeliveryAdviceOrgMembersComplete());
            })
          )
      )
    )
  );

  updateDigitalDeliveryAdviceV3$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineDigitalDeliveryAdviceActions.UPDATE_DIGITAL_DELIVERY_ADVICE_V3),
      mergeMap((updateDigitalDeliveryAdviceAction: MachineDigitalDeliveryAdviceActions.UpdateDigitalDeliveryAdviceV3) =>
        this.digitalDeliveryAdviceService
          .updateDigitalDeliveryAdviceV3(
            updateDigitalDeliveryAdviceAction.request,
            updateDigitalDeliveryAdviceAction.updateDigitalDeliveryAdvice
          )
          .pipe(
            map(
              () =>
                new MachineDigitalDeliveryAdviceActions.UpdateDigitalDeliveryAdviceV3Success(
                  updateDigitalDeliveryAdviceAction.request,
                  updateDigitalDeliveryAdviceAction.updateDigitalDeliveryAdvice
                )
            ),
            catchError((error) => of(new MachineDigitalDeliveryAdviceActions.UpdateDigitalDeliveryAdviceV3Failure(error))),
            finalize(() => {
              this.store.dispatch(new MachineDigitalDeliveryAdviceActions.UpdateDigitalDeliveryAdviceV3Complete());
            })
          )
      )
    )
  );

  updateDigitalDeliveryAdviceOrgMembersSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MachineDigitalDeliveryAdviceActions.UPDATE_DIGITAL_DELIVERY_ADVICE_ORG_MEMBERS_SUCCESS),
        map((action: MachineDigitalDeliveryAdviceActions.UpdateDigitalDeliveryAdviceOrgMembersSuccess) => action.request),
        map((request: IDigitalDeliveryAdviceRequest) => new MachineDigitalDeliveryAdviceActions.GetDigitalDeliveryAdvice(request))
      )
  );

  updateDigitalDeliveryAdviceError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MachineDigitalDeliveryAdviceActions.UPDATE_DIGITAL_DELIVERY_ADVICE_FAILURE),
        map((action: MachineDigitalDeliveryAdviceActions.UpdateDigitalDeliveryAdviceFailure) => action.payload as ServiceCallResponse),
        tap((errorResponse: ServiceCallResponse) => {
          if (errorResponse?.failCode === 409) {
            const error = errorResponse.data as IOnboardClaasMachineError;
            this.alertService.showError(
              this.translateService.instant(error.localMessageKey),
              this.translateService.instant('alert_message.error'),
              'alert-error-icon',
              this.alertOptions
            );
          } else {
            this.alertService.showError(
              this.translateService.instant('global.error_occurred'),
              this.translateService.instant('alert_message.error'),
              'alert-error-icon',
              this.alertOptions
            );
          }
        })
      ),
    { dispatch: false }
  );

  getDigitalDeliveryAdviceLegalTexts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineDigitalDeliveryAdviceActions.GET_DIGITAL_DELIVERY_ADVICE_LEGAL_TEXTS),
      map((action: MachineDigitalDeliveryAdviceActions.GetDigitalDeliveryAdviceLegalTexts) => action.language),
      mergeMap((language: string) =>
        this.digitalDeliveryAdviceService.getDigitalDeliveryAdviceLegalTexts(language).pipe(
          map((legalTexts: IDigitalDeliveryAdviceLegalTexts) =>
            new MachineDigitalDeliveryAdviceActions.GetDigitalDeliveryAdviceLegalTextsSuccess(legalTexts)),
          catchError((error) => of(new MachineDigitalDeliveryAdviceActions.GetDigitalDeliveryAdviceLegalTextsFailure(error))),
          finalize(() => {
            this.store.dispatch(new MachineDigitalDeliveryAdviceActions.GetDigitalDeliveryAdviceLegalTextsComplete());
          })
        )
      )
    )
  );

  getDigitalDeliveryAdvices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineDigitalDeliveryAdviceActions.GET_DIGITAL_DELIVERY_ADVICES),
      map((action: MachineDigitalDeliveryAdviceActions.GetDigitalDeliveryAdvices) => action.request),
      mergeMap((request: IGetDigitalDeliveryAdvicesRequest) =>
        this.digitalDeliveryAdviceService.getDigitalDeliveryAdvices(request).pipe(
          map((digitalDeliveryAdvicesResponse: Paginated<IDigitalDeliveryAdviceSummary>) =>
            new MachineDigitalDeliveryAdviceActions.GetDigitalDeliveryAdvicesSuccess(request, digitalDeliveryAdvicesResponse)),
          catchError((error) => of(new MachineDigitalDeliveryAdviceActions.GetDigitalDeliveryAdvicesFailure(error))),
          finalize(() => {
            this.store.dispatch(new MachineDigitalDeliveryAdviceActions.GetDigitalDeliveryAdvicesComplete());
          })
        )
      )
    )
  );
  /* eslint-enable @typescript-eslint/member-ordering */

  private alertOptions = TOASTR_ALERT_OPTIONS;
}
