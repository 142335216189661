import { MediaMatcher } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  effect,
  input,
  output,
  inject,
} from '@angular/core';
import { ISidenavItem } from '../models/sidenav.model';
import { Store } from '@ngrx/store';
import * as RootState from '@app/shared/state';
import * as OrganisationsState from '@app/shared/state/organisations';
import { Subscription } from 'rxjs';
import { MACHINE_ADVANCED_PERMISSION, PLATTFORM_ADVANCED_PERMISSION } from '@app/shared/utils/permission';
import { Features, IFeatureToggle } from '@app/shared/models/feature-toggle.model';
import { getFeatureToggles } from '@app/shared/state/feature-toggle';
import { CdeSidenavItemComponent } from './cde-sidenav-item/cde-sidenav-item.component';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { MatDrawerContainer, MatDrawer } from '@angular/material/sidenav';
import { sidenavItems } from '@app/shared/utils/nav-items';

@Component({
  selector: 'cde-sidenav',
  templateUrl: './cde-sidenav.component.html',
  styleUrls: ['./cde-sidenav.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatDrawerContainer, MatDrawer, NgClass, NgTemplateOutlet, CdeSidenavItemComponent],
})
export class CdeSidenavComponent implements OnInit, OnDestroy {
  private media = inject(MediaMatcher);
  private store = inject<Store<RootState.IState>>(Store<RootState.IState>);
  private cdr = inject(ChangeDetectorRef);
  isExpanded = input.required<boolean>();
  orgRegistered = input.required<boolean>();
  clickedEvent = output<boolean>();

  public mobileQuery: MediaQueryList;
  public itemsTop: ISidenavItem[] = [];
  public itemsBottom: ISidenavItem[] = [];
  public permissions: string[] = [];
  public permissionsPerRoute: Map<string, string[]> = new Map([
    ['/analytics', MACHINE_ADVANCED_PERMISSION],
    ['/analytics-reports', MACHINE_ADVANCED_PERMISSION],
    ['/analytics-comparison', MACHINE_ADVANCED_PERMISSION],
    ['/configurations', PLATTFORM_ADVANCED_PERMISSION],
    ['/shops', PLATTFORM_ADVANCED_PERMISSION],
  ]);
  public featureToggles: IFeatureToggle[];
  public featureTogglesPerRoute: Map<string, string> = new Map([
    ['/connections', Features.CONNECTION_MANAGER],
    ['/operations', Features.OPERATIONS],
    ['/file-transfer', Features.UPLOAD_DOWNLOAD_CENTER],
    ['/configurations', Features.CONFIGURATOR],
    ['/license', Features.LICENSE],
    ['/parts-shop', Features.NEW_PARTS_SHOP],
  ]);

  private userRoles$ = this.store.select(OrganisationsState.getUserPermissions);
  private userRoleWatcher: Subscription;
  private featureToggles$ = this.store.select(getFeatureToggles);
  private featureToggleWatcher: Subscription;

  private sidenavItems = sidenavItems;

  constructor() {
    this.mobileQuery = this.media.matchMedia('(max-width: 599px})');

    effect(() => {
      this.itemsTop = [];
      this.itemsBottom = [];
      this.filterItems();
    });
  }

  ngOnInit(): void {
    this.userRoleWatcher = this.userRoles$.subscribe((userPermission: string[]) => {
      this.permissions = userPermission;
      this.cdr.detectChanges();
    });
    this.featureToggleWatcher = this.featureToggles$.subscribe((featureToggles: IFeatureToggle[]) => {
      this.featureToggles = featureToggles;
      this.cdr.detectChanges();
    });
  }

  isFeatureToggled(e: ISidenavItem): boolean {
    if (e.route != null) {
      const featureToggle = this.featureTogglesPerRoute.get(e.route);
      if (featureToggle != null) {
        return this.featureToggles?.some((item) => featureToggle === item.id);
      }
    }
    return true;
  }

  isDisabled(e: ISidenavItem): boolean {
    if (e.route != null) {
      const permissions = this.permissionsPerRoute.get(e.route);
      if (permissions != null) {
        return !this.permissions?.some((item) => permissions.includes(item));
      }
    }
    return true;
  }

  ngOnDestroy(): void {
    this.userRoleWatcher?.unsubscribe();
    this.featureToggleWatcher?.unsubscribe();
  }

  public handleClickedEvent($event: boolean) {
    this.clickedEvent.emit($event);
  }

  private filterItems(): void {
    this.sidenavItems.forEach((item: ISidenavItem) => {
      // Skip items that should be hidden
      if (item.hide) return;

      // Skip the file transfer and licenses route if the organization is not registered
      if (!this.orgRegistered() && item.removeForDemoOrg) return;

      item.bottom ? this.itemsBottom.push(item) : this.itemsTop.push(item);
    });
  }
}
