<div class="cancel-license-success-modal">
  <h4 class="no-margin text-center" mat-dialog-title>
    {{ 'license.farm.cancelation_modal.cancelation_modal_success.title' | translate }}
  </h4>

  <div mat-dialog-content>
    <div class="w-full flex justify-center">
      <lib-icon
        class="cancel-license-success-icon mx-auto mb-6"
        iconId="001106"
        namespace="cc"
        [size]="80"
        >
      </lib-icon>
    </div>

    <div class="cancel-license-success-description">
      {{ 'license.farm.cancelation_modal.cancelation_modal_success.description'| translate: { date: (modalInput.cancelDate | dateTime: false) } }}
    </div>
  </div>

  <mat-dialog-actions class="flex !justify-center mt-6">
    <button mat-flat-button mat-dialog-close class="cde-btn white">
      {{ 'global.close' | translate }}
    </button>
  </mat-dialog-actions>
</div>
