import { Component, OnInit, Input, OnChanges, SimpleChanges, ChangeDetectorRef, OnDestroy, inject, output } from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { ISidenavItem } from '../../models/sidenav.model';
import { ToasterClass, ToasterPosition, ToasterType } from '@app/cde-toast/utils/toastr-options';
import { AlertService } from '@app/cde-toast/services/alert.service';
import { Subscription } from 'rxjs';
import { ClaasIconElementsComponentModule } from '@claas/claas-icon-elements-component';
import { NgClass } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'app-cde-sidenav-item',
  templateUrl: './cde-sidenav-item.component.html',
  styleUrls: ['./cde-sidenav-item.component.scss'],
  imports: [RouterLink, RouterLinkActive, NgClass, ClaasIconElementsComponentModule, TranslateModule],
})
export class CdeSidenavItemComponent implements OnInit, OnChanges, OnDestroy {
  private alertService = inject(AlertService);
  private translator = inject(TranslateService);
  private cdr = inject(ChangeDetectorRef);
  @Input() item: ISidenavItem;
  @Input() isExpanded: boolean;
  @Input() hasRoute: boolean;
  @Input() disabled: boolean;
  @Input() permissionsPerRoute: Map<string, string[]>;

  clickedEvent = output<boolean>();

  public disableRouterLink = false;

  private routeWatcher: Subscription;

  public ngOnInit(): void {
    const checkedRoute = this.item.route != null ? this.item.route : '';
    this.disableRouterLink = this.permissionsPerRoute?.get(checkedRoute) !== undefined && this.disabled;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const checkedRoute = this.item.route != null ? this.item.route : '';
    if (changes.disabled) {
      this.disableRouterLink = this.permissionsPerRoute?.get(checkedRoute) !== undefined && this.disabled;
      this.cdr.detectChanges();
    }
  }

  public ngOnDestroy(): void {
    this.routeWatcher?.unsubscribe();
  }

  public toggleOnClick(item: ISidenavItem): void {
    if (this.disableRouterLink) {
      const options = {
        positionClass: ToasterPosition.bottomCenter,
        showToastAction: false,
        type: ToasterType.snackbarLarge,
        toastClass: ToasterClass.snackbarLarge,
        closeButton: false,
        disableTimeOut: false,
      };
      this.alertService.showInfo(
        this.translator.instant('alert_message.no_permission'),
        this.translator.instant('alert_message.note'),
        'alert-info-icon',
        options
      );
      return;
    }

    this.clickedEvent.emit(true);
  }
}
