import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, finalize, map, of, switchMap, take } from 'rxjs';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import * as LicenseActions from './license.actions';
import * as RootState from '../';

import { LicenseService } from '@app/shared/services/license.service';
import { ICancelOrganizationLicense, IOrganizationLicense, IOrganizationLicensesContainer } from '@app/shared/models/license.model';
import { TOASTR_ALERT_OPTIONS } from '@app/cde-toast/utils/toastr-alert-options';
import { AlertService } from '@app/cde-toast/services/alert.service';
import { ToasterPosition, ToasterType } from '@app/cde-toast/utils/toastr-options';
import { MatDialog } from '@angular/material/dialog';
import { CancelLicenseSuccessModalComponent, CancelLicenseSuccessModalInput } from '@app/features/license/views/farm-management-license/cancel-license-success-modal/cancel-license-success-modal.component';

@Injectable()
export class LicenseEffects {
  private actions$ = inject(Actions);
  private alertService = inject(AlertService);
  private licenseService = inject(LicenseService);
  private store = inject<Store<RootState.IState>>(Store<RootState.IState>);
  private translate = inject(TranslateService);
  private dialog = inject(MatDialog);

  private alertOptions = {
    ...TOASTR_ALERT_OPTIONS,
    timeOut: 3000,
  };

  /* eslint-disable @typescript-eslint/member-ordering */
  getFarmLicenses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LicenseActions.GET_FARM_CONNECT_LICENSES),
      switchMap((data: { organizationId: string; language: string }) =>
        this.licenseService.getFarmLicenses(data.organizationId, data.language).pipe(
          map((farmLicenses: IOrganizationLicensesContainer) => new LicenseActions.GetFarmConnectLicensesSuccess(farmLicenses)),
          catchError((error) => of(new LicenseActions.GetFarmConnectLicensesFailure(error))),
          finalize(() => this.store.dispatch(new LicenseActions.GetFarmConnectLicensesComplete()))
        )
      )
    )
  );

  activateOrganizationLicense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LicenseActions.ACTIVATE_ORGANIZATION_LICENSE),
      switchMap((data: { organizationId: string; licenseId: string; language: string }) =>
        this.licenseService.activateOrganizationLicense(data.organizationId, data.licenseId, data.language).pipe(
          map((farmLicense: IOrganizationLicense) => {
            this.alertService.callSnackbarSuccess(
              this.translate.instant('license.farm.license_activation.success'),
              '',
              ToasterType.snackbarSuccess,
              {
                positionClass: ToasterPosition.bottomCenter,
              }
            );

            return new LicenseActions.ActivateOrganizationLicenseSuccess(farmLicense);
          }),
          catchError((error) => {
            this.alertService.showError(
              this.translate.instant('license.farm.license_activation.failure'),
              this.translate.instant('alert_message.try_again'),
              'alert-error-icon',
              this.alertOptions
            );

            return of(new LicenseActions.ActivateOrganizationLicenseFailure(error));
          }),
          finalize(() => this.store.dispatch(new LicenseActions.ActivateOrganizationLicenseComplete()))
        )
      )
    )
  );

  cancelOrganizationLicense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LicenseActions.CANCEL_ORGANIZATION_LICENSE),
      map((action: LicenseActions.CancelOrganizationLicense) => action.cancelOrganizationLicense),
      switchMap((cancelOrganizationLicense: ICancelOrganizationLicense) =>
        this.licenseService.cancelOrganizationLicense(cancelOrganizationLicense).pipe(
          map((cancelledOrganizationLicense: IOrganizationLicense) => {
            this.dialog.open(CancelLicenseSuccessModalComponent, {
              height: 'auto',
              width: '520px',
              panelClass: ['cancel-license-success-dialog'],
              data: {
                cancelDate: cancelledOrganizationLicense.validUntil
              } as CancelLicenseSuccessModalInput,
              autoFocus: false,
            }).afterClosed().pipe(take(1)).subscribe(() => {});

            return new LicenseActions.CancelOrganizationLicenseSuccess(
              cancelledOrganizationLicense,
              cancelOrganizationLicense.organizationId,
              cancelOrganizationLicense.language
            );
          }),
          catchError((error) => {
            this.alertService.showError(
              this.translate.instant('global.error_occurred'),
              this.translate.instant('alert_message.try_again'),
              'alert-error-icon',
              this.alertOptions
            );

            return of(new LicenseActions.CancelOrganizationLicenseFailure(error));
          }),
          finalize(() => this.store.dispatch(new LicenseActions.CancelOrganizationLicenseComplete()))
        )
      )
    )
  );

  cancelOrganizationLicenseSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LicenseActions.CANCEL_ORGANIZATION_LICENSE_SUCCESS),
      map((action: LicenseActions.CancelOrganizationLicenseSuccess) => ({
        organizationId: action.organizationId,
        language: action.language
      })),
      map(({ organizationId, language }) => new LicenseActions.GetFarmConnectLicenses(
        organizationId, language
      ))
    )
  );
  /* eslint-enable @typescript-eslint/member-ordering */
}
